<template>
  <div>
    <p>{{ msg }}</p>
    <el-button type="success" @click="get">连接钱包</el-button>
    <el-button type="primary" @click="getETH">获取账户信息</el-button>
    <el-button type="warning" @click="getTransfer">ETH转帐</el-button>
    <el-button type="success" @click="getTokenBalance">代币余额</el-button>
    <el-button type="danger" @click="getTokenTransfer">转账代币</el-button>
    <el-button type="info" @click="getAllowance">查询授权金额</el-button>
    <!--    <button @click="getApprove">授权</button>-->
    <!--    <button @click="createAccount">创建账号</button>-->
  </div>
</template>

<script>
// import vue from "vue";
import Web3 from 'web3'
import abi from '../abi/ERC20.json'

let bip39 = require('bip39')
let HDWallet = require('ethereum-hdwallet')
import { ElMessage } from 'element-plus'
export default {
  // components: { ElButton },
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Connect',
  props: {
    msg: String
  },
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    getData() {
      console.log('first')
    },
    get() {  // 唤起钱包
      if (window.ethereum) {
        window.ethereum.enable().then((res) => {
          ElMessage.success("当前钱包地址: " + res[0]);
        });
      } else {
        ElMessage.error("请安装MetaMask钱包");
      }
    },
    createAccount() {
      let web3 = new Web3(window.web3.currentProvider)
      let account = web3.eth.accounts.create("123456")
      console.log("生成的账号信息：", account)
      let keystore = account.encrypt("123456")
      console.log("keystore:", keystore)

      let mnemonic1 = bip39.generateMnemonic()
      console.log("助记词1:", mnemonic1)

      let mnemonic2 = bip39.generateMnemonic(128, null, bip39.wordlists.chiness_simplified)
      console.log('助记词2:', mnemonic2);
      this.getAddress(mnemonic2)
    },
    getAddress(mnemonic) {
      const seed = bip39.mnemonicToSeed(mnemonic)//根据助记词生成seed
      const hdwallet = HDWallet.fromSeed(seed.toString())//通过seed获取hdWallet
      const key = hdwallet.derive("m/44' /60' /0/0")//源于，得自。设置地址路径
      console.log("PrivateKey = " + key.getPrivateKey().toString('hex'))//私钥
      console.log("PublicKey = " + key.getPublicKey().toString('hex'))//公钥
      const EthAddress = '0x' + key.getAddress().toString('hex')//地址
      console.log("Eth Address = " + EthAddress)
    },
    async getETH() {
      let web3 = new Web3(window.web3.currentProvider)
      let fromAddress = await web3.eth.getAccounts()
      // console.log(web3.eth.getBalance(fromAddress[0]))
      // console.log(fromAddress)
      await web3.eth.getBalance(fromAddress[0], (err, res) => {
        if (!err) {
          ElMessage.success("ETH余额: " + res / Math.pow(10, 18));
        }
      })
    },
    //ETH转账
    async getTransfer() {
      let web3 = new Web3(window.web3.currentProvider)
      let fromAddress = await web3.eth.getAccounts()
      let amount = 0.01 * Math.pow(10, 18);
      let toAddress = "0x1428092D427119f9141682360F82B743191463AB";
      web3.eth.sendTransaction({
        gas: 21000,
        gasPrice: 5000000000,
        from: fromAddress[0],
        to: toAddress,
        value: amount
      }, (err, result) => {
        console.log("转账Hash=", result)
      })
    },
    //查询代币余额
    async getTokenBalance() {
      if (window.web3) {
        var web3 = web3 = new Web3(window.web3.currentProvider);
        let fromAddress = await web3.eth.getAccounts()
        let ethContract = new web3.eth.Contract(abi, "0xdAC17F958D2ee523a2206206994597C13D831ec7") //所有代币的abi可以通用（abi,合约地址）
        let balance = await ethContract.methods.balanceOf(fromAddress[0]).call()
        ElMessage.success(balance / Math.pow(10, 18))
      }
    },
    //直接转账充币地址
    async getTokenTransfer() {
      if (window.web3) {
        let web3 = new Web3(window.web3.currentProvider)
        let ethContract = new web3.eth.Contract(abi, "0xdAC17F958D2ee523a2206206994597C13D831ec7") //所有代币的abi可以通用（abi,合约地址）
        //转账数量
        // let amount = 100*Math.pow(10,18);//转账100个
        let amount = 100 * Math.pow(10, 4);//转账100个
        //小狐狸账户
        let fromAddress = await web3.eth.getAccounts()
        //接收地址
        let toAddress = "0x1428092D427119f9141682360F82B743191463AB";
        ethContract.methods.transfer(toAddress, amount + '').send({from: fromAddress[0]})
      }

    },
    //查询授权金额
    async getAllowance() {
      if (window.web3) {
        let web3 = new Web3(window.web3.currentProvider)
        let fromAddress = await web3.eth.getAccounts()
        let ethContract = new web3.eth.Contract(abi, "0xdAC17F958D2ee523a2206206994597C13D831ec7") //所有代币的abi可以通用（abi,合约地址）
        let toAddress = "0x1428092D427119f9141682360F82B743191463AB";//被授权地址
        let balance = await ethContract.methods.allowance(fromAddress[0], toAddress).call()
        ElMessage.success("授权金额: " + balance / Math.pow(10, 18))
      }
    },

    //授权
    async getApprove() {
      if (window.web3) {
        let web3 = new Web3(window.web3.currentProvider)
        let ethContract = new web3.eth.Contract(abi, "0xdAC17F958D2ee523a2206206994597C13D831ec7") //所有代币的abi可以通用（abi,合约地址）
        //授权数量
        let amount = 100 * Math.pow(10, 18);//转账100个
        let toAddress = "0xcaD75EADAf24F41d6274E129d7aE54764d7cd8E7";//被授权地址
        //小狐狸账户
        let fromAddress = await web3.eth.getAccounts()
        ethContract.methods.approve(toAddress, amount + '').send({from: fromAddress[0]})
      }
    }
  }
}
</script>

