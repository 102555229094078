import { createRouter, createWebHistory } from "vue-router"

const routes = [
  {
    path: '/',
    component: () => import('@/components/HelloWorld')
  },
  // {
  //   path: '/chain',
  //   name: "chain",
  //   component: () => import('@/components/chain')
  // },
]
export const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

export default router
